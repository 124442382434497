/* eslint-disable linebreak-style */
import React from 'react';

export const SpectrumReachLogo = (props) => (
  <svg
    version="1.1"
    id="SpectrumReach-Logo"
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    viewBox={`0 0 ${props.v1} ${props.v2} `}
    x="0px"
    y="0px"
    xmlSpace="preserve"
    href="/"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g id="SR_Spot_Logo" data-name="SR Spot Logo">
          <path
            fill="#003057"
            className="cls-1"
            d="M5.24 43.64q4.46 3.81 13.37 3.8 8.66 0 13.37-3.66 4.73-3.95 4.72-11a12.22 12.22
    0 0 0-1.44-6 10.61 10.61 0 0 0-4.42-4.22 18.45 18.45 0 0
    0-3.43-1.32c-1.61-.5-9.6-2.68-10.41-2.89-4.45-1.09-7.28-2.57-7.3-5.2.23-5.1 5-5.51
    7.6-5.51a10.8 10.8 0 0 1 6.42 1.7 5.93 5.93 0 0 1 2.51 5h8.9a14.56 14.56 0 0 0-4.72-10.67Q26.08
    0 18.09 0q-8.52 0-13 3.54A12.37 12.37 0 0 0 .66 13.63c0 5.5 2.62 8.74 5 10.22a31 31 0 0 0 8.91
    3l3.54.77c6.15 1.5 9.67 3.39 9.56 6.43a4.9 4.9 0 0 1-2.09 4.2 11.31 11.31 0 0 1-6.16 1.44 12.43
    12.43 0 0 1-7.21-1.83q-2.49-1.58-3-5.51H0q.39 7.47 5.24 11.27M62.54 29.45c0-3.43-.62-10.67-7.64-10.67-6.65
    0-7.37 7.07-7.37 10.67 0 9.11 5.18 10.27 7.64 10.27 6.37 0 7.37-6.75 7.37-10.27m-15 25.82a4.44 4.44 0 0 1-4.48
    4.47h-4.49V27c.52-8.73 3.7-15.68 17-16.16
    5.73.07 9.89 2.35 12.35 5.23 2.64 3.42 3.76 7.68 3.76 13q0 8.29-3.95 13.44a12.33 12.33 0 0
    1-10.14 5 16.16 16.16 0 0 1-10-3.06v10.84M90.1
    39.62c-2.27-.11-6.59-.55-7-8.12h22.71v-2.72c-.16-5.7-1.52-10-4.19-13.14q-4.33-4.73-12.19-4.72-7.47
    0-11.67 4.85t-4.04 13.11q0 8.77 4.19 13.5t11.67 4.85a16.68 16.68 0 0 0 10.22-3 14.33 14.33 0 0 0
    5.52-8.4h-7.89c-1.17 3.29-4.13 4-7.33 3.8m-.39-21.1c5.63-.07 6.51 4.72 6.68 7.08H83s-.12-7
    6.68-7.08M130.87 35.83h8.62s-2.34 11.4-15.36 11.4-16.21-10.45-16.21-17.31 1.21-18.58
    15.71-19c13.39-.19 15.59 11.41 15.59 11.41h-9.17s-.89-3.93-6.35-3.8-6.89 5.25-6.89 10.12 1.22 11
    7.28 11c5.76.17 6.27-3 6.78-3.8M150.6 21h5.4v-9.16h-5.37v-5.9a4.21 4.21 0 0 0-4.33-4.46h-4.72V40c0
    4 1.56 6.42 4.94 6.42h4.1zM165.59 46.51v-18q0-3.93 1.84-5.77t5.63-1.74h3v-9.36a31.89 31.89 0 0
    0-8.19.92 17.34 17.34 0 0 0-5.84 2.88 14.27 14.27 0 0 0-4.11 5.56 21.62 21.62 0 0 0-1.5 8.58v16.93zM178.67
    11.7h9.18V34s-.33 6 6 6a5.72 5.72 0 0 0  6.15-5.76V16s.31-4.32 4.29-4.32h4.76v20.69c-.07 1.13 1.2 14.84-15.2
    14.78s-15.1-13.86-15.21-14.68zM242.21 46.62h-9.09V24.23c0-2.58-1.07-6.06-6.19-6.06s-6.06 3.95-6.06
    6.06v22.39h-9.22V26.07c0-6 1.77-9.62 4.67-12s6.57-3.11 10.34-3.29a26.62 26.62 0 0 1 5.93.66 12.54 12.54 0
    0 1 5.27 2.63 11.57 11.57 0 0 1 4.68-2.43 21.67 21.67 0 0
    1 5.59-.86c11.25-.06 15.76 5.09 16.14 6.74v29.1h-5.21a4.41 4.41 0 0
    1-4.47-4.48V24.23c0-2.54-.87-6.06-6.19-6.06s-6.19
    3.95-6.19 6.06z"
          />
          <path
            fill="#0099D8"
            className="cls-2 arrow"
            d="M266.82 17.52v29.06l19.15-14.53-19.15-14.53z"
          />
          <path
            fill="#003057"
            className="cls-1"
            d="M171.07 58.87a5.67 5.67 0 0 1 2.15 1.6 7.47 7.47 0 0 1 1.15 1.92 6.51 6.51 0
    0 1 .42 2.41A6.92 6.92 0 0 1 174 68a4.61 4.61 0 0 1-2.72 2.23 4.24 4.24 0 0 1 2.21 1.77
    7.5 7.5 0 0 1 .66 3.57v1.54a11.08 11.08 0
    0 0 .13 2.13 1.94 1.94 0 0 0 .88 1.3v.57h-5.27c-.15-.5-.25-.91-.31-1.22a10.8 10.8 0
    0 1-.21-2v-2.13a4.36 4.36 0 0 0-.75-2.92 3.9 3.9 0
    0 0-2.73-.73h-4.69v9h-4.67V58.28h10.94a9.94 9.94 0 0 1 3.6.59m-9.87 3.38v6.15h5.14a5.62
    5.62 0 0 0 2.3-.37 2.59 2.59 0 0 0 1.36-2.58 2.87 2.87
    0 0 0-1.31-2.79 4.69 4.69 0 0 0-2.21-.41zM196.09
    62.33h-12.1v4.86h11.11v3.97h-11.11v5.88h12.66v4.11h-17.33V58.28h16.77v4.05zM207.36 58.28h5.41l8.09
    22.87h-5.18l-1.51-4.7h-8.42l-1.55 4.7h-5zm-.31 14.23h5.86l-2.89-9zM225.13
    60.67a9.41 9.41 0 0 1 7-2.82q5.7 0 8.35 3.78a8.26 8.26 0 0 1
    1.56 4.27h-4.78a6.37 6.37 0 0 0-1.17-2.48 5.19 5.19 0 0 0-7.81.61 10.3 10.3 0 0 0-1.47 6q0 3.85 1.55
    5.77a4.9 4.9 0 0 0 4 1.91 4.47 4.47 0 0 0 3.73-1.71 7.12 7.12
    0 0 0 1.19-2.66H242a10.35 10.35 0 0 1-3.14 6.09 9.15 9.15 0 0 1-6.47 2.34 9.73 9.73 0
    0 1-7.68-3.17c-1.86-2.12-2.79-5-2.79-8.72q0-6
    3.18-9.23M245.82 81.15V58.28h4.75V67h8.89v-8.72h4.75v22.87h-4.75V70.94h-8.89v10.21h-4.75z"
          />
        </g>
        <path
          fill="#003057"
          className="cls-1"
          d="M271.38 61.27h2.73a2.56
    2.56 0 0 1 1.83.64 1.68 1.68 0 0 1 .49 1.21c0 1.09-.8 1.71-1.9 1.86l2.14 2.78h-.61L274
    65.08h-2.13v2.69h-.48zm2.67 3.37c1.09 0 1.9-.55 1.9-1.49 0-.87-.69-1.42-1.87-1.42h-2.22v2.93z"
        />
        <path
          fill="#003057"
          className="cls-1"
          d="M273.68 71.06a6.43 6.43 0 1 1 6.43-6.43 6.44 6.44 0 0 1-6.43
    6.43zm0-12.49a6.06 6.06 0 1 0 6.06 6.06 6.07 6.07 0 0 0-6.06-6.06z"
        />
      </g>
    </g>
  </svg>
);

export const SpectrumReachLogoAlt = (props) => (
  <svg
    version="1.1"
    id="SpectrumReach-Logo"
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    viewBox={`0 0 ${props.v1} ${props.v2} `}
    x="0px"
    y="0px"
    xmlSpace="preserve"
    href="/"
  >
    <g transform="translate(0.000000,344.000000) scale(0.100000,-0.100000)" stroke="none">
      <path
        fill="#ffffff"
        d="M615 3430 c-105 -10 -248 -50 -320 -88 -27 -15 -80 -56 -116 -92
  -113 -112 -156 -235 -146 -423 6 -125 32 -204 93 -287 82 -111 211 -171 523
  -241 360 -80 495 -152 508 -271 18 -161 -114 -258 -351 -258 -150 1 -275 46
  -345 127 -35 40 -71 122 -71 164 0 18 -11 19 -196 19 l-196 0 7 -57 c36 -309
  220 -498 540 -558 129 -24 426 -17 540 14 309 82 471 303 452 616 -9 143 -54
  245 -145 327 -87 79 -137 98 -532 207 -269 74 -334 97 -387 137 -62 47 -80
  124 -47 202 33 80 100 125 215 143 226 35 431 -67 455 -227 l6 -44 184 0 184
  0 0 38 c0 20 -7 65 -15 98 -43 185 -165 331 -333 399 -122 50 -329 72 -507 55z"
      />
      <path
        fill="#ffffff"
        d="M5942 2508 l3 -863 24 -48 c12 -26 39 -60 59 -75 34 -26 42 -27 164
  -30 l128 -4 0 531 0 530 113 3 112 3 3 193 2 192 -115 0 -115 0 0 147 c0 128
  -3 152 -21 190 -37 77 -63 88 -222 91 l-138 4 3 -864z"
      />
      <path
        fill="#ffffff"
        d="M2153 2966 c-280 -54 -434 -188 -505 -443 -22 -77 -22 -91 -26 -836
  l-3 -759 130 4 c127 3 130 3 168 34 71 56 73 68 73 347 0 196 3 247 13 247 7
  0 17 -4 23 -9 21 -22 137 -72 196 -85 284 -65 508 14 648 228 93 144 132 298
  132 521 1 403 -143 640 -447 735 -89 28 -294 36 -402 16z m274 -341 c85 -35
  160 -144 183 -265 13 -70 13 -246 -1 -312 -38 -184 -157 -288 -313 -276 -198
  16 -299 164 -298 433 0 252 77 394 237 436 43 12 147 3 192 -16z"
      />
      <path
        fill="#ffffff"
        d="M3635 2971 c-144 -24 -252 -77 -345 -171 -149 -149 -218 -398 -191
  -688 21 -225 77 -359 200 -481 142 -141 319 -196 554 -170 246 26 417 138 512
  334 24 49 47 100 50 113 l6 22 -170 0 c-138 0 -171 -3 -171 -14 0 -7 -18 -34
  -40 -59 -53 -60 -115 -82 -230 -81 -122 1 -165 16 -226 78 -55 56 -80 113 -91
  203 l-6 53 478 0 478 0 -6 143 c-10 252 -71 422 -197 547 -76 77 -158 123
  -267 151 -87 22 -261 32 -338 20z m224 -327 c96 -28 164 -118 178 -235 l6 -49
  -276 0 c-152 0 -278 3 -280 8 -2 4 1 32 7 64 36 172 193 264 365 212z"
      />
      <path
        fill="#ffffff"
        d="M5100 2973 c-383 -51 -570 -305 -569 -773 0 -433 209 -698 583 -739
  260 -29 489 57 623 233 46 61 96 157 109 209 l6 27 -181 0 -181 0 -19 -37
  c-24 -48 -45 -67 -101 -92 -141 -62 -312 -23 -387 88 -63 94 -95 278 -75 436
  23 184 89 286 206 320 70 20 155 15 219 -13 45 -20 117 -92 117 -117 0 -13 29
  -15 196 -15 l196 0 -6 28 c-4 15 -25 65 -47 111 -77 156 -220 272 -394 316
  -62 16 -234 27 -295 18z"
      />
      <path
        fill="#ffffff"
        d="M9350 2965 c-186 -38 -329 -136 -400 -276 -11 -20 -29 -70 -42 -110
  -21 -72 -22 -91 -26 -586 l-3 -513 195 0 195 0 3 513 c3 507 3 512 25 554 48
  89 131 129 256 121 80 -5 118 -21 165 -69 61 -63 62 -71 62 -621 l0 -498 190
  0 189 0 3 503 3 503 30 59 c44 87 98 118 214 123 74 4 95 1 139 -18 53 -23 94
  -62 115 -109 8 -18 13 -171 17 -482 l5 -456 27 -40 c43 -65 81 -77 241 -81
  l137 -4 0 619 -1 618 -28 38 c-41 54 -95 95 -186 138 -120 58 -217 80 -381 86
  -203 8 -337 -19 -459 -92 l-57 -35 -45 32 c-97 66 -230 98 -408 97 -60 -1
  -139 -7 -175 -14z"
      />
      <path
        fill="#ffffff"
        d="M7250 2943 c-310 -29 -504 -144 -609 -358 -17 -36 -41 -105 -53 -153
  -21 -82 -22 -109 -23 -517 l0 -430 192 -3 192 -2 3 437 3 438 28 58 c50 101
  119 135 295 144 l112 6 0 193 0 194 -52 -2 c-29 0 -69 -3 -88 -5z"
      />
      <path
        fill="#ffffff"
        d="M7500 2452 c0 -462 1 -504 19 -575 43 -168 133 -284 271 -350 113
  -54 213 -70 389 -65 122 4 159 10 228 32 145 47 247 134 307 260 54 113 56
  139 56 690 l0 509 -124 -5 c-99 -4 -130 -9 -157 -24 -40 -23 -75 -72 -89 -123
  -6 -21 -10 -203 -10 -446 0 -410 0 -410 -24 -461 -43 -92 -117 -134 -237 -134
  -123 0 -214 69 -238 181 -7 32 -11 226 -11 529 l0 480 -190 0 -190 0 0 -498z"
      />
      <path
        fill="#0099D8"
        d="M11200 2090 l0 -601 293 222 c160 122 338 256 394 298 56 42 101 79
  100 83 -2 5 -747 574 -777 593 -7 4 -10 -186 -10 -595z"
      />
      <path
        fill="#ffffff"
        d="M9635 996 c-151 -37 -257 -148 -302 -319 -23 -86 -22 -272 1 -352 55
  -188 168 -290 348 -315 193 -27 354 53 434 216 14 29 28 71 31 93 l6 41 -95 0
  -95 0 -34 -67 c-42 -82 -90 -113 -176 -113 -135 0 -221 104 -231 281 -13 232
  75 374 233 374 87 0 140 -32 179 -108 l29 -57 99 0 99 0 -7 38 c-18 95 -84
  191 -166 240 -92 55 -241 75 -353 48z"
      />
      <path
        fill="#ffffff"
        d="M6570 511 l0 -481 95 0 95 0 2 188 3 187 111 3 c203 5 222 -12 233
  -215 4 -77 11 -145 15 -151 5 -8 43 -12 117 -12 115 0 126 5 92 43 -15 15 -19
  45 -24 156 -6 155 -20 199 -75 234 -19 12 -34 24 -34 27 0 3 18 15 39 27 58
  33 91 106 91 199 0 79 -9 107 -54 172 -20 28 -48 51 -85 70 l-56 27 -282 3
  -283 3 0 -480z m526 275 c30 -30 34 -40 34 -85 0 -115 -28 -131 -225 -131
  l-145 0 0 125 0 125 151 0 151 0 34 -34z"
      />
      <path
        fill="#ffffff"
        d="M7520 510 l0 -480 365 0 365 0 0 85 0 85 -262 2 -263 3 -3 122 -3
  123 236 0 236 0 -3 83 -3 82 -232 3 -233 2 0 100 0 100 255 0 255 0 0 85 0 85
  -355 0 -355 0 0 -480z"
      />
      <path
        fill="#ffffff"
        d="M8691 963 c-14 -36 -331 -926 -331 -930 0 -2 47 -3 104 -3 l105 0 32
  100 33 100 176 0 176 0 33 -100 32 -100 109 0 109 0 -18 53 c-43 120 -310 873
  -316 890 -6 15 -22 17 -120 17 l-113 0 -11 -27z m239 -565 c0 -5 -54 -8 -121
  -8 -93 0 -120 3 -117 13 3 6 31 91 63 187 l59 175 58 -180 c32 -99 58 -183 58
  -187z"
      />
      <path
        fill="#ffffff"
        d="M10320 510 l0 -480 95 0 95 0 2 213 3 212 188 3 187 2 0 -215 0 -215
  100 0 100 0 0 480 0 480 -100 0 -100 0 -2 -182 -3 -183 -185 0 -185 0 -3 183
  -2 182 -95 0 -95 0 0 -480z"
      />
    </g>
  </svg>
);
