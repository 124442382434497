import React, { useEffect } from "react";
import { styled } from "@material-ui/core/styles";
import { useInput } from "./../utils/forms";
import { validatePassword } from "./../utils/common";
import { Auth } from "aws-amplify";
import Button from "@material-ui/core/Button";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Typography, Divider, Grid } from "@material-ui/core";
import theme from "../styles/theme";
import { useStyles } from "../styles/formStyles";
import { PageLoader } from "../themeComponents/Loader";
import PasswordRulesContainer from "./PasswordRulesContainer";
import { passwordPolicies, SINGLESIGNON } from "../utils/types";
// Kite
import { KiteTextInput } from "@kite/react-kite";
import { InfoIcon } from "./kiteComponents/IconComponents";

const DButton = styled(Button)({
  marginTop: theme.spacing(4),
});

const DHelpSection = styled("div")({
  marginTop: theme.spacing(4),
});

const Signup: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [signupError, setSignupError] = React.useState<any>(null);
  const location = useLocation();
  const classes = useStyles();
  const history = useHistory();
  let { search } = useLocation();

  const params = new URLSearchParams(location?.search);
  const callbackUrl: any = params.get("callbackUrl");
  const clientId: any = params.get("clientId");
  const { value: username, bind: bindUsername } = useInput("");
  const { value: email, bind: bindEmail } = useInput("");
  const { value: phone, bind: bindPhone } = useInput("");
  const { value: password, bind: bindPassword } = useInput("");
  const { value: confirmPassword, bind: bindConfirmPassword } = useInput("");

  useEffect(() => {
    if (callbackUrl && clientId) {
      localStorage.setItem("callbackUrl", callbackUrl);
      localStorage.setItem("clientId", clientId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removePasswordPolicy = (error: string): string => {
    let strippedError: string = error;

    passwordPolicies.forEach((policy: string) => {
      strippedError = strippedError.replace(policy, "");
    });

    return strippedError;
  };

  const handleSignUp = async (e: React.SyntheticEvent<Element, Event>) => {
    e.preventDefault();
    setLoading(true);

    if (password !== confirmPassword) {
      setLoading(false);
      return;
    }

    if (/\s/.test(username) || /\s/.test(password) || /\s/.test(confirmPassword)) {
      setSignupError("Username and password should not contain spaces");
      setLoading(false);
      return;
    }
    
    try {
      await Auth.signUp({
        username: username,
        password: confirmPassword,
        attributes: {
          email,
          phone_number: "+1" + phone,
          name: username,
        },
      });
      localStorage.setItem("confirmEmail", email);
      localStorage.setItem("confirmUsername", username);
      localStorage.setItem("tempFlowLogin", password);
      history.push("/confirmation" + search);
      window.location.reload();
    } catch (error: any) {
      setSignupError(removePasswordPolicy(error.message));
    }
    setLoading(false);
  };

  const fieldsFill =
    !username || !email || !phone || !password || !confirmPassword;
  const passwordsMatch = password === confirmPassword;
  const submitDisabled = fieldsFill || !passwordsMatch;

  return (
    <div className={classes.root}>
      <form className={classes.form} onSubmit={handleSignUp}>
        <Typography className={classes.cardAccentText}>
          {SINGLESIGNON.accent}
        </Typography>
        <Typography className={classes.cardText} variant="h1">
          {SINGLESIGNON.createANewAccount}
        </Typography>
        <Typography className={classes.cardSubText} variant="subtitle1">
          {SINGLESIGNON.createAccountSubtext}
        </Typography>
        <KiteTextInput
          className={classes.input}
          errorMessage=""
          id=""
          label="Username"
          name=""
          {...bindUsername}
        />
        <Divider className={classes.divider} />
        <KiteTextInput
          className={classes.input}
          errorMessage=""
          id=""
          label="Email Address"
          name=""
          {...bindEmail}
        />
        <KiteTextInput
          className={classes.input}
          errorMessage=""
          id=""
          label="Phone Number"
          name=""
          {...bindPhone}
        />
        <Divider className={classes.divider} />
        <KiteTextInput
          className={classes.input}
          errorMessage=""
          id=""
          label="Password"
          type="password"
          name=""
          {...bindPassword}
        />
        <PasswordRulesContainer
          password={password}
          confirmPassword={confirmPassword}
        />
        <KiteTextInput
          className={classes.input}
          errorMessage=""
          id=""
          label="Confirm Password"
          type="password"
          name=""
          {...bindConfirmPassword}
        />
        {signupError && (
          <Grid container direction="row" alignItems="center">
            <Grid item style={{ width: "17px", marginRight: "5px" }}>
              <InfoIcon fill="#D6312B" size="10px" icon="ki-caution-circle-f" />
            </Grid>
            <Grid item>
              <Typography variant="subtitle2" className={classes.errorMessage}>
                {signupError}
              </Typography>
            </Grid>
          </Grid>
        )}
        <DButton
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          disabled={!validatePassword(password) || loading || submitDisabled}
          className={classes.button + " " + classes.smallWidthButton}
        >
          <Typography className={classes.buttonSubText}>
            {SINGLESIGNON.createAccount}
          </Typography>
        </DButton>
        <DHelpSection>
          <Divider className={classes.divider} />
          <Typography className={classes.helpSection}>
            <span className={classes.linkSpan}> {SINGLESIGNON.needHelp} </span>{" "}
            <Link to="/contact-us" className={classes.link}>
              {SINGLESIGNON.contactUs}
            </Link>
          </Typography>
        </DHelpSection>
        {loading && <PageLoader />}
      </form>
    </div>
  );
};

export default Signup;
