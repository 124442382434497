import React from "react";
import { Typography } from "@material-ui/core/";
import { styled } from "@material-ui/core/styles";
import { SINGLESIGNON, SSOFooterOptions, space } from "../utils/types";
import theme from "../styles/theme";

const OptionsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(10),
});

const StyledTypography = styled("span")({
  color: theme.palette.text.secondary,
  textDecoration: "underline",
});

const StyledCopyRight = styled(Typography)({
  color: "grey",
});

export default function SSOFooter() {
  return (
    <OptionsContainer>
      <div>
        {SSOFooterOptions.map((option, index) => (
          <a href={option.link} key={index} style={{ textDecoration: "none" }}>
            <Typography variant="caption">
              <StyledTypography>{option.name}</StyledTypography>
              {index !== SSOFooterOptions.length - 1 && (
                <>
                  {space}
                  <span
                    style={{
                      color: "grey",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    {" "}
                    |{" "}
                  </span>
                  {space}
                </>
              )}
            </Typography>
          </a>
        ))}
      </div>
      <StyledCopyRight variant="caption">{SINGLESIGNON.copyright}</StyledCopyRight>
    </OptionsContainer>
  );
}
