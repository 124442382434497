import React from "react";
import { Switch, Route } from "react-router-dom";
import { Box } from "@material-ui/core";
import Amplify from "aws-amplify";
import Signup from "./components/Signup";
import Confirmation from "./components/Confirmation";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import { COGNITO } from "./configs/aws";
import ProtectedRoute from "./components/ProtectedRoute";
import { styled } from "@material-ui/core/styles";
import ForgotPassword from "./components/ForgotPassword";
import { SpectrumReachLogo } from "./themeComponents/Logo";
import BackNavigation from "./components/BackNavigation";
import SSOFooter from "./components/SSOFooter";
import ContactUs from "./components/ContactUs";
import HeaderServices from "./components/HeaderServices";
import NotFoundPage from "./components/NotFoundPage";

Amplify.configure({
  aws_cognito_region: COGNITO.REGION,
  aws_user_pools_id: COGNITO.USER_POOL_ID,
  aws_user_pools_web_client_id: COGNITO.APP_CLIENT_ID,
  authenticationFlowType: "USER_PASSWORD_AUTH",
});

const DHeader = styled("header")({
  height: "40px",
  color: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "60px 10px 20px 10px",
  fontSize: "18px",
  fontWeight: 600,
  width: 695,
  margin: "0 auto",
});

const BodyContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  margin: "0 auto",
});

const MainCard = styled(Box)({
  minHeight: 300,
  backgroundColor: "#FFFFFF",
  paddingLeft: "72px",
  paddingRight: "72px",
  paddingTop: "74px",
  paddingBottom: "74px",
  margin: "0 auto",
});

const NavigationSection = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  transform: "translateY(-30px)",
});

const App: React.FC = () => {
  return (
    <div>
      <DHeader>
        <Box>
          <SpectrumReachLogo width={120} height={33} v1={250} v2={85} />
        </Box>
        <HeaderServices />
      </DHeader>
      <BodyContainer>
        <MainCard>
          <NavigationSection>
            <BackNavigation />
          </NavigationSection>
          <Switch>
            <Route path="/contact-us">
              <ContactUs />
            </Route>
            <Route path="/signup">
              <Signup />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/confirmation">
              <Confirmation />
            </Route>
            <Route path="/forgot-password">
              <ForgotPassword />
            </Route>
            <Route path="/" exact>
              <ProtectedRoute component={Dashboard} />
            </Route>
            <Route path="*">
              <NotFoundPage />
            </Route>
          </Switch>
        </MainCard>
        <SSOFooter />
      </BodyContainer>
    </div>
  );
};

export default App;
