import React from 'react';
import { KiteIcon } from '@kite/react-kite';

export const InfoIcon = (props : any) => {
    const { ariaLabel, className, fill, icon, size, ...other } = props;
    return (
        <KiteIcon
            ariaLabel={ariaLabel}
            className={className}
            fill={fill}
            icon={icon}
            size={size}
            {...other}
        />
    );
    };

export const NextIcon = () => {
    return (
        <>
        <KiteIcon
            ariaLabel=""
            badge=""
            className=""
            fill="#0073D1"
            icon="ki-arrow-right"
            size="16px"
        />
        </>
    )
}