import React, { useEffect } from "react";
import {
  Checkbox,
  FormControlLabel,
  Typography,
  Divider,
  Grid,
  Button,
} from "@material-ui/core";
import { Link, useHistory, useLocation } from "react-router-dom";
import { PageLoader } from "../../src/themeComponents/Loader";
import { useStyles } from "../styles/formStyles";
import { useInput } from "./../utils/forms";
import { Auth } from "aws-amplify";
import axios from "axios";
import { API_URLS } from "../configs/aws";
import { isUserAuthenticated } from "../utils/common";
import { SINGLESIGNON } from "../utils/types";
// Kite
import { KiteTextInput } from "@kite/react-kite";
import { InfoIcon, NextIcon } from "./kiteComponents/IconComponents";

interface LoginResponse {
  code: string;
  expires: string;
  maxAge: string;
  token: string;
  username: string;
}

const Signup: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [loginError, setLoginError] = React.useState<any>(null);
  const [rememberMe, setRememberMe] = React.useState(false);

  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const callbackUrl: any = params.get("callbackUrl");
  const clientId: any = params.get("clientId");

  const hasConfirmDetails =
    localStorage.getItem("confirmEmail") &&
    localStorage.getItem("confirmUsername");
  const redirectUrl = localStorage.getItem("callbackUrl");
  const savedClientID = localStorage.getItem("clientId");
  const findRequestingApplication = (url: string) => {
    switch (true) {
      case url.includes("clientportal"):
        return "clientportal";
      case url.includes("onespot"):
        return "onespot";
      case url.includes("d22x"):
        return "clientportal";
      case url.includes("d1t5cqnhbypzk"):
        return "clientportal";
      case url.includes("dw2igr6u6bjpx"):
        return "clientportal";
      default:
        return "clientportal";
    }
  };

  const requestingApplication = findRequestingApplication(
    redirectUrl || callbackUrl || ""
  );
  const savedUsername = localStorage.getItem("username");
  const newAccountUsername = localStorage.getItem("confirmUsername");

  const { value: email, bind: bindEmail } = useInput(
    savedUsername || newAccountUsername || ""
  );
  const { value: password, bind: bindPassword } = useInput("");

  useEffect(() => {
    if (isUserAuthenticated()) {
      if (
        callbackUrl &&
        clientId &&
        (callbackUrl !== localStorage.getItem("callbackUrl") ||
          clientId !== localStorage.getItem("clientId"))
      ) {
        let prC = localStorage.getItem("clientId");
        let prCa = localStorage.getItem("callbackUrl");
        callbackUrl
          ? history.push(
              `/?newAuthCodeRequired=true&clientId=${clientId}&callbackUrl=${callbackUrl}&prC=${prC}&prCa=${prCa}`
            )
          : history.push("/");
      } else {
        callbackUrl
          ? history.push(`/?clientId=${clientId}&callbackUrl=${callbackUrl}`)
          : history.push("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (callbackUrl && clientId) {
      localStorage.setItem("callbackUrl", callbackUrl);
      localStorage.setItem("clientId", clientId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callbackUrl, clientId]);

  const handleGetAuthCode = async () => {
    rememberMe
      ? localStorage.setItem("username", email)
      : localStorage.removeItem("username");
    axios
      .post( API_URLS.authCodeURL || "/missing-REACT_APP_AUTH_CODE_URL", {
        username: email,
        password,
        redirectUrl,
        clientId: savedClientID,
        env: process.env.REACT_APP_ENV || "development",
        application: requestingApplication,
      })
      .then((result: any) => {
        setLoading(false);
        const data: LoginResponse = result.data;
        if (data.code) {
          localStorage.setItem("code", data.code);
        }
        document.cookie = `cognito=${data?.token};Expires=${data?.expires};Max-Age=${data?.maxAge}`;
        document.cookie = `user=${data?.username};Expires=${data?.expires};Max-Age=${data?.maxAge}`;
        setLoading(false);
        data.code &&
          callbackUrl &&
          history.push(`/?clientId=${clientId}&callbackUrl=${callbackUrl}`);
        data.code && !callbackUrl && history.push("/");
        !data.code && setLoginError("Please try again in a few minutes.");
      })
      .catch((error) => {
        console.log("error: ", error);
        setLoginError(
          "Please use a supported origin such as Client Portal or OneSpot.TV."
        );
        setLoading(false);
      });
  };

  async function handleLogin() {
    if (!localStorage.getItem("callbackUrl")) {
      setLoginError(
        "Please use a supported origin such as Client Portal or OneSpot.TV."
      );
      setLoading(false);
      return;
    }
    setLoginError("");
    try {
      await Auth.signIn(email, password);
      handleGetAuthCode();
    } catch (error: any) {
      switch (error.code) {
        case "UserNotConfirmedException":
          setLoginError("Please confirm your email address.");
          !hasConfirmDetails &&
            setLoginError(
              'Please confirm your email address. Follow the "Forgot Password" link below.'
            );
          hasConfirmDetails && history.push("/confirmation");
          break;
        case "PasswordResetRequiredException":
          setLoginError(
            'Please reset your password. Follow the "Forgot Password" link below.'
          );
          history.push("/forgot-password?resetRequired=true&username=" + email);
          break;
        case "NotAuthorizedException":
          setLoginError("Invalid username or password. Please try again.");
          break;
        case "NEW_PASSWORD_REQUIRED":
          !callbackUrl
            ? history.push(
                "/forgot-password?resetRequired=true&username=" + email
              )
            : history.push(
                "/forgot-password?resetRequired=true&username=" +
                  email +
                  "&callbackUrl=" +
                  callbackUrl
              );
          setLoginError("Please choose a new password.");
          break;
        case "UserNotFoundException":
          setLoginError("Invalid username or password. Please try again.");
          break;
        default:
          setLoginError(
            "An unexpected error occurred. Please try again later."
          );
      }
      setLoading(false);
    }
  }

  const handleInitiateLoginFlow = async (
    event: React.SyntheticEvent<Element, Event>
  ) => {
    setLoading(true);
    event.preventDefault();
    handleLogin();
  };

  const loginDisabled = !email || !password;

  return (
    <div className={classes.root}>
      <form className={classes.form} onSubmit={handleInitiateLoginFlow}>
        <Typography className={classes.cardAccentText}>
          {SINGLESIGNON.accent}
        </Typography>
        <Typography className={classes.cardText} variant="h1">
          {SINGLESIGNON.yourSpectrumReach}
        </Typography>
        <Typography className={classes.cardSubText} variant="body1">
          {SINGLESIGNON.loginSubText}
        </Typography>
        <KiteTextInput
          autoFocus
          className={classes.input}
          errorMessage=""
          id=""
          label="Username"
          name=""
          {...bindEmail}
        />
        <KiteTextInput
          className={classes.input}
          errorMessage=""
          id=""
          label="Password"
          name=""
          {...bindPassword}
          type="password"
        />
        {loginError && (
          <Grid container direction="row" alignItems="center">
            <Grid item style={{ width: "17px", marginRight: "5px" }}>
              <InfoIcon fill="#D6312B" size="5px" icon="ki-caution-circle-f" />
            </Grid>
            <Grid item>
              <Typography variant="subtitle2" className={classes.errorMessage}>
                {loginError}
              </Typography>
            </Grid>
          </Grid>
        )}
        <FormControlLabel
          className={classes.rememberMe}
          control={
            <Checkbox
              name="checked"
              color="primary"
              onClick={() => setRememberMe(!rememberMe)}
              checked={rememberMe}
            />
          }
          label={
            <Typography className={classes.rememberMeText}>
              {SINGLESIGNON.rememberMe}
            </Typography>
          }
        />
        <Button
          className={classes.button}
          disabled={loginDisabled}
          variant="outlined"
          color="primary"
          type="submit"
        >
          <Typography variant="subtitle1">{SINGLESIGNON.signIn}</Typography>
        </Button>
        <Grid
          container
          className={classes.createNewAccountContainer}
          direction="row"
          alignItems="center"
          onClick={(e) => {
            history.push("/signup");
          }}
        >
          <Grid item>
            <Typography
              className={classes.createNewAccountContent}
              variant="subtitle1"
            >
              {" "}
              {SINGLESIGNON.createANewAccount}{" "}
            </Typography>
          </Grid>
          <Grid item style={{ width: "17px", marginTop: 4 }}>
            <NextIcon />
          </Grid>
        </Grid>

        <Divider className={classes.divider} />
        <Typography className={classes.troubleSigningIn}>
          {SINGLESIGNON.troubleSigningIn}
        </Typography>
        <Typography>
          <Link to="/forgot-password" className={classes.link}>
            {SINGLESIGNON.forgotPassword}
          </Link>
          <span className={classes.linkSpan}> or </span>
          <Link to="/contact-us" className={classes.link}>
            {SINGLESIGNON.contactUs}
          </Link>{" "}
          <span className={classes.linkSpan}> {SINGLESIGNON.forMoreHelp}</span>
        </Typography>
      </form>
      {loading && <PageLoader />}
    </div>
  );
};

export default Signup;
