interface FooterOption {
    name: string;
    link: string;
}
interface Application {
    name: string;
    paramVal: string;
}
interface Service {
    name: string;
    shortName: string;
    bg: string;
}
interface ContactUsInfo {
    href: string;
    text: string;
    phone: string;
}

interface ISingleSignOn {
    accent: string;
    yourSpectrumReach: string;
    loginSubText: string;
    rememberMe: string;
    signIn: string;
    createANewAccount: string;
    createAccount: string;
    troubleSigningIn: string;
    forgotPassword: string;
    needHelp: string;
    contactUs: string;
    forMoreHelp: string;
    backToSignIn: string;
    verifyYourAccount: string;
    confirmSubText: string;
    verify: string;
    resendCode: string;
    accountVerifiedSuccess: string;
    accountVerifiedSuccessSubText: string;
    continueToLogin: string;
    contactUsRepresentative: string;
    welcome: string;
    welcomeSubText: string;
    goTo: string;
    continueAs: string;
    signInWithOtherAccount: string;
    verificationCodeWillBeSent: string;
    passwordResetRequired: string;
    sendVerificationCode: string;
    verifyIdentity: string;
    enterVerificationCode: string;
    defaultVerificationCodeMesssage: string;
    changePassword: string;
    resetPassword: string;
    goBack: string;
    passwordResetSuccess: string;
    passwordResetSuccessSubText: string;
    loginToSupportedApps: string;
    confirmUsername: string;
    orCancelContinue: string;
    cancel: string;
    pageNotFound: string;
    pageNotFoundSubText: string;
    passwordRulesHeading: string;
    passwordLengthRule: string;
    andMustContain: string;
    upper: string;
    lower: string;
    number: string;
    special: string;
    passwordMatch: string;
    createAccountSubtext: string;
    copyright: string;
    continueTo: string;
}

export const SINGLESIGNON: ISingleSignOn = {
    accent: "SINGLE SIGN ON",
    yourSpectrumReach: "Your Spectrum Reach",
    loginSubText: "Your Spectrum Reach account right at your fingertips. Use one account to access all of your Spectrum Reach apps.",
    rememberMe: "Remember Me",
    signIn: "Sign In",
    createANewAccount: "Create a New Account",
    createAccount: "Create Account",
    troubleSigningIn: "Trouble signing in?",
    forgotPassword: "Forgot Password",
    needHelp: "Need Help?",
    contactUs: "Contact Us",
    forMoreHelp: "for more help.",
    // Back Navigation
    backToSignIn: "Back to Sign In",
    // Confirmation Page
    verifyYourAccount: "Verify Your Account",
    confirmSubText: "Confirm your information to confirm your identity and use Spectrum Reach apps. An email was sent to: ",
    verify: "Verify",
    resendCode: "Resend Code",
    accountVerifiedSuccess: "Success! Your Account Has Been Verified",
    accountVerifiedSuccessSubText: "Your account has successfully been created. You can now log into Client Portal and OneSpot.tv using your new account.",
    continueToLogin: "Continue to Login",
    continueTo: "Continue to ",
    // Contact Us Page
    contactUsRepresentative: "Contact us and a representative will get back to you shortly.",
    // Dashboard
    welcome: "Welcome",
    welcomeSubText: "Your Spectrum Reach account right at your fingertips. Use one account to access all of your Spectrum Reach apps.",
    goTo: "Go to ",
    // Dashboard Pivot Point
    continueAs: "Continue as ",
    signInWithOtherAccount: "Sign in with another account",
    // Forgot Password Page
    verificationCodeWillBeSent: "A verification code will be sent to your email.",
    passwordResetRequired: "You need to reset your password to continue with Spectrum Reach.",
    sendVerificationCode: "Send Verification Code to Email",
    // Forgot Password Page --- Verify Identity
    verifyIdentity: "Verify Your Identity",
    enterVerificationCode: "Please enter the verification code sent to your email address below:",
    defaultVerificationCodeMesssage: "If an account associated with your username address exists, a verification code will be sent to your inbox.",
    // Forgot Password Page --- Reset Password
    changePassword: "Change Your Password",
    resetPassword: "Reset your Password",
    goBack: "Go Back",
    // Forgot Password Page --- Success
    passwordResetSuccess: "Your Password Has Been Successfully Updated",
    passwordResetSuccessSubText: "Your password has been updated. You can now use your new password to log into Client Portal and OneSpot.tv.",
    // Info Sections
    loginToSupportedApps: "Use your Spectrum Reach account to sign in to both Client Portal and OneSpot.tv.",
    // New Auth Code Required
    confirmUsername: "Confirm Username",
    orCancelContinue: "Or Cancel to continue to your original application.",
    cancel: "Cancel",
    // 404 Page
    pageNotFound: "404 Not Found",
    pageNotFoundSubText: "Please check your path and try again.",
    // Password Rules
    passwordRulesHeading: "Please choose a password that follows these guidelines:",
    passwordLengthRule: "Must be at least 8 characters or longer",
    andMustContain: "and must contain each of the following:",
    upper: "Uppercase letter",
    lower: "Lowercase letter",
    number: "Number",
    special: "Special character",
    passwordMatch: "Passwords must match",
    // Signup Page
    createAccountSubtext: "Create a Spectrum Reach account to access all of your applications.",
    // Footer
    copyright: "© 2024 Spectrum Reach",
}

export const passwordPolicies: ReadonlyArray<string> = [
    "Password did not conform with policy: ",
    "PreSignUp failed with error",
];

export const SSOFooterOptions: FooterOption[] = [
    {
        name: "Terms & Conditions",
        link: "https://spectrumreach.com/terms-conditions",
    },
    {
        name: "Your Privacy Rights",
        link: "https://www.spectrum.com/policies/your-privacy-rights",
    },
    {
        name: "California Consumer Privacy Rights",
        link: "https://www.spectrum.com/policies/california",
    },
    {
        name: "Do Not Sell My Information",
        link: "https://www.spectrum.com/policies/your-privacy-rights-opt-out",
    },
];

export const SupportedApplications: Application[] = [
    { name: "Client Portal", paramVal: "clientportal" },
    { name: "Onespot.tv", paramVal: "onespot" },
    { name: "Your Application", paramVal: "localhost" },
    { name: "Client Portal SAP", paramVal: "clientportalsap" }
];

export const SupportedServices: Service[] = [
    {
        name: "CLIENTPORTAL",
        shortName: "CP",
        bg: "#045990",
    },
    {
        name: "ONESPOT.TV",
        shortName: "OS",
        bg: "#00938C",
    },
];

export const contactUsInfo: ContactUsInfo = {
    href: "mailto:sso-support@spectrumreach.com",
    text: "sso-support@spectrumreach.com",
    phone: "(877) 320-1157",
}

export const space: string = "\u00A0\u00A0";